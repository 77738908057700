const soAfpUrl = {
  local: "https://localhost:44330/",
  development:
    "https://api-s-uat.sgmarkets.com/services/fundonboarding/private/v1/",
  homologation: "https://clienttemplate-api-preprod.fr.world.socgen/api/v1/",
  uat: "https://clienttemplate-api-preprod.fr.world.socgen/api/v1/",
  production: "https://api-s.sgmarkets.com/services/fundonboarding/private/v1/",
};
const soUrl = {
  local: "https://localhost:44330/",
  development: "https://securitiesonboarding-uat.sgmarkets.com/",
  homologation: "https://securitiesonboarding-preprd.sgmarkets.com/",
  uat: "https://securitiesonboarding-preprd.sgmarkets.com/",
  production: "https://securitiesonboarding.sgmarkets.com/",
};

export const getSoAfpBaseUrl = (env: string) => {
  return (soAfpUrl as any)[env];
};
export const getSoUrl = (env: string) => {
  return (soUrl as any)[env];
};
