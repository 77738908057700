import React, { Fragment } from "react";

interface Props {
  headerTitleText?: string;
}

const TitleTemplate: React.FC<Props> = ({ headerTitleText }) => (
  <Fragment>
    <span>{headerTitleText}</span>
    <i className="icon icon-sm text-secondary ms-4px">launch</i>
  </Fragment>
);

export default TitleTemplate;
