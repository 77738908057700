import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import ItemInfoTemplate from "./item-info-template";
import { ListItemContext } from "@sgss-ttd-widgets/components";

interface Props {
  items: any;
  language?: string;
  soFundFollowUpUrl: (item: any) => any;
}

const generateItems = (
  items: T[],
  soFundFollowUpUrl: any,
  language: string | undefined
) =>
  items?.map((item, index) => {
    return (
      <ListItemContext.Provider value={{ item, index }} key={index}>
        <div
          className="card card-filling"
          onClick={() => soFundFollowUpUrl(item)}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex">
            <div
              className={`d-flex flex-column w-100 text-truncate pt-3 pb-2 px-3`}
            >
              <div className={`d-flex align-items-center mb-8px`}>
                <ItemInfoTemplate language={language} />
                {/* TO BE IMPLEMENTED */}
                {/* <button className="btn btn-link btn-lg py-0 px-0">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => soFundFollowUpUrl(item)}
                  >
                    <i className="icon">info_outline</i>
                  </a>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </ListItemContext.Provider>
    );
  });

const BodyTemplate = ({
  items,
  soFundFollowUpUrl,
  language,
}: Props): JSX.Element => {
  return (
    <Fragment>
      {items ? generateItems(items, soFundFollowUpUrl, language) : ""}
    </Fragment>
  );
};

export default BodyTemplate;
