import React, { Fragment, useContext } from "react";
import {
  ListItemContext,
  ListItemContextModel,
  TooltipTrigger,
} from "@sgss-ttd-widgets/components";
import { FundFollowUp, WorkflowStep } from "../model/fund-follow-up.model";
import { ItemTitleTemplate, ItemTextTemplate, ItemBodyTemplate } from ".";

interface Props {
  language?: any;
}

const getTranslatedValue = (stage: string, language: any) => {
  switch (stage) {
    case "STEPS":
      return language == "FR" ? "étape" : "steps";
    case "PENDING FORM CLIENT":
      return language == "FR"
        ? "Remplissage du formulaire client en attente"
        : "Pending form client filling";
    case "ACCEPTATION STEPS":
      return language == "FR" ? "Étapes d’acceptation" : "Acceptation steps";
    case "COMMERCIAL STEPS":
      return language == "FR" ? "Étapes commerciales" : "Commercial steps";
    case "ACOUNT OPENING SUPPORT":
      return language == "FR"
        ? "Assistance à l’ouverture de compte"
        : "Account opening support";
    case "TECHNICAL SET-UP":
      return language == "FR" ? "Mise en place technique" : "Technical set-up";
    case "KYC":
      return "KYC";
    case "INVOICING":
      return language == "FR" ? "Facturation" : "Invoicing";
    case "ALERT":
      return language == "FR"
        ? "Merci de remplir le formulaire client"
        : "Please fill in the client form";
    case "ONBOARDED":
      return language == "FR" ? "Intégré" : "Onboarded";
    default:
      return;
  }
};

const isKYCInProgress = (
  fund: FundFollowUp,
  workflowStep: WorkflowStep,
  currentDate: Date
): boolean => {
  let withinTimePeriod: boolean;
  const KYC = fund.fundWorkflowSteps.find(
    x => x.processGroup.toUpperCase() == "KYC"
  )?.processes[0];
  if (KYC && KYC.isCompleted) {
    const kycValidatedPlusTwodays = new Date(KYC.endTime);
    kycValidatedPlusTwodays.setDate(kycValidatedPlusTwodays.getDate() + 2);
    withinTimePeriod =
      !workflowStep.processes[workflowStep.totalNumberOfProcess - 1]
        .isCompleted && currentDate <= kycValidatedPlusTwodays;
  } else {
    withinTimePeriod = true;
  }
  return withinTimePeriod;
};

const isAccountSupportInProgress = (
  fund: FundFollowUp,
  workflowStep: WorkflowStep,
  currentDate: Date
): boolean => {
  let withinTimePeriod: boolean;
  const accOpeningSupport = fund.fundWorkflowSteps.find(
    x => x.processGroup.toUpperCase() == "ACOUNT OPENING SUPPORT"
  )?.processes[0];
  if (accOpeningSupport && accOpeningSupport.isCompleted) {
    const accountValidatedPlusTwodays = new Date(accOpeningSupport.endTime);
    accountValidatedPlusTwodays.setDate(
      accountValidatedPlusTwodays.getDate() + 2
    );
    withinTimePeriod =
      !workflowStep.processes[workflowStep.totalNumberOfProcess - 1]
        .isCompleted && currentDate <= accountValidatedPlusTwodays;
  } else {
    withinTimePeriod = true;
  }
  return withinTimePeriod;
};

const isRedAlert = (
  workflowStep: WorkflowStep,
  fund: FundFollowUp
): boolean => {
  const isComplete =
    workflowStep.totalNumberOfProcess === workflowStep.numberOfProcessCompleted;

  if (
    workflowStep.processGroup.toUpperCase() == "FORM CLIENT FILLING" &&
    !fund?.isExpired
  ) {
    const currentDate = new Date();
    let withinTimePeriod;
    switch (workflowStep.totalNumberOfProcess) {
      case 1: {
        const creationPlusTwodays = new Date(fund.creationDate);
        creationPlusTwodays.setDate(creationPlusTwodays.getDate() + 2);
        withinTimePeriod =
          !workflowStep.processes[0].isCompleted &&
          currentDate <= creationPlusTwodays;
        break;
      }
      case 2:
        if (
          workflowStep.processes[1].activityName.toUpperCase() ===
          "KYC DOCS SUBMISSION"
        ) {
          const overviewCompletePlusTwoDays = new Date(
            workflowStep.processes[0].endTime
          );

          overviewCompletePlusTwoDays.setDate(
            overviewCompletePlusTwoDays.getDate() + 2
          );
          withinTimePeriod =
            !workflowStep.processes[1].isCompleted &&
            currentDate <= overviewCompletePlusTwoDays;
        } else if (
          workflowStep.processes[1].activityName.toUpperCase() ===
          "CLIENT ACCOUNT INFORMATION"
        ) {
          withinTimePeriod = isKYCInProgress(fund, workflowStep, currentDate);
        }
        break;
      case 3: {
        if (
          workflowStep.processes[2].activityName.toUpperCase() ===
          "CLIENT ACCOUNT INFORMATION"
        ) {
          withinTimePeriod = isKYCInProgress(fund, workflowStep, currentDate);
        } else if (
          workflowStep.processes[2].activityName.toUpperCase() ===
          "CLIENT REGISTER SERVICE INFORMATION"
        ) {
          withinTimePeriod = isAccountSupportInProgress(
            fund,
            workflowStep,
            currentDate
          );
        }
        break;
      }
      case 4:
        withinTimePeriod = isAccountSupportInProgress(
          fund,
          workflowStep,
          currentDate
        );
        break;
    }
    return withinTimePeriod || isComplete ? false : true;
  } else {
    return fund?.isExpired && !isComplete ? true : false;
  }
};

const ItemInfoTemplate = ({ language }: Props): JSX.Element => {
  const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
  const item: any = itemContext?.item;

  const { fundWorkflowSteps }: FundFollowUp = itemContext?.item || undefined;

  const clientForms = fundWorkflowSteps?.find(
    x => x.processGroup === "Form client filling"
  );

  const isClientFormInProgress =
    clientForms?.numberOfProcessCompleted !== clientForms?.totalNumberOfProcess;
  let latestStep: WorkflowStep | undefined = undefined;
  let latestStepName: string | undefined = undefined;
  let hasRedFlagAlert = false;
  let alertText: string | undefined = undefined;
  if (isClientFormInProgress && clientForms) {
    latestStep = clientForms;
    hasRedFlagAlert = isRedAlert(latestStep, item);
    alertText = hasRedFlagAlert ? getTranslatedValue("ALERT", language) : "";
    latestStepName = "";
  } else if (fundWorkflowSteps) {
    for (let i = fundWorkflowSteps.length - 1; i >= 0; i--) {
      if (
        fundWorkflowSteps[i].numberOfProcessCompleted !==
        fundWorkflowSteps[i].totalNumberOfProcess
      ) {
        latestStep = fundWorkflowSteps[i];
        latestStepName = getTranslatedValue(
          latestStep?.processGroup.toUpperCase(),
          language
        );
        break;
      }
    }
  }

  return (
    <Fragment>
      <div className="d-flex flex-column text-truncate flex-grow-1 me-12px">
        <div className="d-flex align-items-center justify-content-between mb-4px">
          <div className="d-flex flex-column text-truncate">
            <div className="fs-16 text-truncate pe-3 fw-semibold ">
              <ItemTitleTemplate />
            </div>
          </div>

          {latestStepName || isClientFormInProgress ? (
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column align-items-end me-12px">
                {isClientFormInProgress ? (
                  <span className="badge badge-flat-warning badge-lg rounded-pill p-0">
                    {getTranslatedValue("PENDING FORM CLIENT", language)}
                    {isClientFormInProgress && hasRedFlagAlert && alertText ? (
                      <button className="btn btn-flat-danger text-danger ms-4px p-0">
                        <TooltipTrigger placement="left" content={alertText}>
                          <i className="icon icon-sm lh-1">flag</i>
                        </TooltipTrigger>
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                ) : (
                  <span className="badge badge-flat-success badge-lg rounded-pill p-0">
                    {latestStepName}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column align-items-end me-12px">
                <span className="badge badge-flat-success badge-lg rounded-pill p-0">
                  {getTranslatedValue("ONBOARDED", language)}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between mb-16px">
          <div className="d-flex flex-column text-truncate">
            <ItemTextTemplate language={language} />
          </div>

          {latestStepName || isClientFormInProgress ? (
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column align-items-end me-12px">
                <div className="fw-medium text-secondary">
                  <span className="fw-semibold text-primary">
                    {latestStep!.numberOfProcessCompleted}
                  </span>
                  /{latestStep!.totalNumberOfProcess}{" "}
                  {getTranslatedValue("STEPS", language)}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <ItemBodyTemplate />
      </div>
    </Fragment>
  );
};

export default ItemInfoTemplate;
