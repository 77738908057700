import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
const Pager = ({ pages, currentPage, onPageChange }) => {
    const [currentPageState, setCurrentPageState] = useState(1);
    const pagerBtnRefs = useRef([]);
    useEffect(() => {
        if (currentPage && currentPage !== currentPageState) {
            setCurrentPageState(currentPage);
        }
    }, [currentPage]);
    const handlePageChange = (page) => {
        for (const pagerBtn of pagerBtnRefs.current) {
            pagerBtn.blur();
        }
        setCurrentPageState(page);
        onPageChange && onPageChange(page);
    };
    const generatePagerData = (value, isSpecial = false) => {
        return {
            content: isSpecial
                ? _jsx("em", Object.assign({ className: "icon", "aria-hidden": "true" }, { children: "more_horiz" }))
                : _jsx("span", { children: value }),
            value
        };
    };
    const generatePagerDataRange = (pageCount, pageStart = 1) => {
        const pageData = [];
        for (let i = 0; i < pageCount; i++) {
            pageData.push(generatePagerData(pageStart + i));
        }
        return pageData;
    };
    const generatePageButtons = (pages) => {
        let buttonsData = [];
        if (pages < 6) {
            buttonsData = generatePagerDataRange(pages);
        }
        else if (currentPageState < 3) {
            buttonsData = [
                ...generatePagerDataRange(3),
                generatePagerData(currentPageState + 3, true),
                generatePagerData(pages)
            ];
        }
        else if (currentPageState > pages - 2) {
            buttonsData = [
                generatePagerData(1),
                generatePagerData(currentPageState - 3, true),
                ...generatePagerDataRange(3, pages - 2)
            ];
        }
        else if (currentPageState === 3) {
            buttonsData = [
                ...generatePagerDataRange(4),
                generatePagerData(currentPageState + 3, true),
                generatePagerData(pages)
            ];
        }
        else if (currentPageState === pages - 2) {
            buttonsData = [
                generatePagerData(1),
                generatePagerData(currentPageState - 3, true),
                ...generatePagerDataRange(4, pages - 3)
            ];
        }
        else {
            buttonsData = [
                generatePagerData(1),
                generatePagerData(currentPageState - 3, true),
                ...generatePagerDataRange(3, currentPageState - 1),
                generatePagerData(currentPageState + 3, true),
                generatePagerData(pages)
            ];
        }
        return buttonsData.map(({ content, value }, index) => {
            return (_jsx("li", Object.assign({ className: `page-item ${currentPageState === value ? 'active' : ''}` }, { children: _jsx("button", Object.assign({ className: "page-link btn btn-lg", type: "button", "aria-current": "true", onClick: () => handlePageChange(value), ref: ref => !!ref && pagerBtnRefs.current.push(ref) }, { children: content })) }), `key_${index}`));
        });
    };
    const previousPageBtnActive = currentPageState > 1;
    const nextPageBtnActive = pages && currentPageState < pages;
    pagerBtnRefs.current = [];
    return (pages && pages > 0
        ? (_jsx("nav", Object.assign({ "aria-label": 'Page navigation' }, { children: _jsxs("ul", Object.assign({ className: 'pagination pagination-borderless' }, { children: [_jsx("li", Object.assign({ className: 'page-item me-2' }, { children: _jsx("button", Object.assign({ type: "button", "aria-label": "Previous page", className: `page-link btn btn-lg ${!previousPageBtnActive ? 'disabled' : ''}`, onClick: () => previousPageBtnActive && handlePageChange(currentPageState - 1), ref: ref => !!ref && pagerBtnRefs.current.push(ref) }, { children: _jsx("em", Object.assign({ className: "icon", "aria-hidden": "true" }, { children: "keyboard_arrow_left" })) })) })), generatePageButtons(pages), _jsx("li", Object.assign({ className: 'page-item ms-2' }, { children: _jsx("button", Object.assign({ type: "button", "aria-label": "Next page", className: `page-link btn btn-lg ${!nextPageBtnActive ? 'disabled' : ''}`, onClick: () => nextPageBtnActive && handlePageChange(currentPageState + 1), ref: ref => !!ref && pagerBtnRefs.current.push(ref) }, { children: _jsx("i", Object.assign({ className: 'icon' }, { children: "keyboard_arrow_right" })) })) }))] })) })))
        : _jsx(_Fragment, {}));
};
export default Pager;
