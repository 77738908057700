export const withMock = false; // true

export function getFundFollowUp(
  soAfpBaseUrl: string,
  proceesId: string
): string {
  return soAfpBaseUrl + `funds/${proceesId}/processes`;
}

export function getUserFunds(
  soAfpBaseUrl: string,
  pageNumber: number,
  bdrID?: string
): string {
  if (bdrID) {
    return (
      soAfpBaseUrl +
      `fundsByMails?pageSize=5&pageNumber=${pageNumber}&bdrId=${bdrID}`
    );
  } else
    return soAfpBaseUrl + `fundsByMails?pageSize=5&pageNumber=${pageNumber}`;
}
