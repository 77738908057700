interface Props {
  fetch: any;
  requestInfos: any;
  status: any;
  onSuccess: (response: Response) => any;
  onError?: () => any;
  canFetch: () => boolean;
  cleanValues?: any[];
}

export function fetchAsync(props: Props): void {
  const {
    fetch,
    requestInfos,
    onSuccess,
    onError,
    cleanValues,
    canFetch,
    status,
  } = props;

  const handleSuccess = (
    isMounted: boolean,
    response: Response,
    onSuccess: (response: Response) => any
  ): any => {
    if (isMounted && onSuccess) return onSuccess(response);
  };

  const handleError = (onError?: () => any): any => {
    if (onError) return onError();
  };

  if (status !== "loaded") {
    if (fetch) {
      if (canFetch()) {
        let isMounted = true;
        const fetchData = async (): Promise<void> => {
          await fetch(requestInfos.url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              ...(requestInfos.headers.NavigateAs !== undefined &&
              requestInfos.headers.NavigateAs !== ""
                ? {
                    "Navigate-As": requestInfos.headers.NavigateAs,
                  }
                : {}),
            },
            method: requestInfos.method,
          })
            .then((response: Response) => {
              handleSuccess(isMounted, response, onSuccess(response));
            })
            .catch(() => {
              handleError(onError);
            });
        };
        fetchData();
      }
    }
  }
}
