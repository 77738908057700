var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
const RenderPopover = (props) => {
    const { content } = props, otherProps = __rest(props, ["content"]);
    return (_jsx(Popover, Object.assign({ id: "popover" }, otherProps, { children: _jsx(Popover.Body, Object.assign({ className: 'p-0' }, { children: content })) })));
};
const PopoverTrigger = ({ placement, content, popperConfig, children }) => (children
    ? _jsx(OverlayTrigger, Object.assign({ placement: placement, delay: { show: 250, hide: 400 }, overlay: props => RenderPopover(Object.assign(Object.assign({}, props), { content })), popperConfig: popperConfig }, { children: children }))
    : _jsx(Fragment, {}));
export default PopoverTrigger;
