import React, { useContext } from "react";
import {
  ListItemContext,
  ListItemContextModel,
  ProgressBar,
} from "@sgss-ttd-widgets/components";
import { FundFollowUp } from "../model/fund-follow-up.model";

const ItemBodyTemplate = (): JSX.Element => {
  const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
  const { fundWorkflowSteps }: FundFollowUp = itemContext?.item || undefined;
  const numberOfProcess = fundWorkflowSteps?.length;
  const numberOfProcessComplete = fundWorkflowSteps?.filter(
    x => x.totalNumberOfProcess === x.numberOfProcessCompleted
  ).length;
  const progressComplete = `${Math.trunc(
    (numberOfProcessComplete / numberOfProcess) * 100
  )}%`;

  return <ProgressBar progressDone={progressComplete} />;
};

export default ItemBodyTemplate;
