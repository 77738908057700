var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const RenderTooltip = (props) => {
    const { content } = props, otherProps = __rest(props, ["content"]);
    return (_jsx(Tooltip, Object.assign({ id: "tooltip" }, otherProps, { children: content })));
};
const TooltipTrigger = ({ placement, content, children }) => (children
    ? _jsx(OverlayTrigger, Object.assign({ placement: placement, delay: { show: 250, hide: 400 }, overlay: props => RenderTooltip(Object.assign(Object.assign({}, props), { content })) }, { children: children }))
    : _jsx(Fragment, {}));
export default TooltipTrigger;
