import React, { Fragment, useContext } from "react";
import {
  ListItemContext,
  ListItemContextModel,
} from "@sgss-ttd-widgets/components";

const ItemTitleTemplate = (): JSX.Element => {
  const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
  const item: any = itemContext?.item;

  return (
    <Fragment>
      <span>{item?.name}&nbsp;</span>
    </Fragment>
  );
};

export default ItemTitleTemplate;
