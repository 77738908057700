import React, { Fragment } from "react";

interface Props {
  createClick?: () => void;
  duplicateClick?: () => void;
  createText?: string;
  duplicateText?: string;
  isFundsAvailable?: boolean;
}

const ToolBarTemplate: React.FC<Props> = ({
  createClick,
  duplicateClick,
  createText,
  duplicateText,
  isFundsAvailable,
}) => (
  <Fragment>
    <div className="d-flex gap-8px">
      <button
        className="btn btn-discreet-primary btn-lg btn-icon-start"
        onClick={() => createClick && createClick()}
      >
        <i className="icon">add</i>
        {createText}
      </button>
      <button
        className="btn btn-discreet-primary btn-lg btn-icon-start "
        onClick={() => duplicateClick && duplicateClick()}
        disabled={!isFundsAvailable}
      >
        <i className="icon">content_copy</i>
        {duplicateText}
      </button>
    </div>
  </Fragment>
);

export default ToolBarTemplate;
