(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-bootstrap"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-bootstrap"], factory);
	else if(typeof exports === 'object')
		exports["$"] = factory(require("react"), require("react-bootstrap"));
	else
		root["$"] = factory(root["react"], root["react-bootstrap"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__636__) => {
return 