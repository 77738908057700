import React, { Fragment, useContext } from "react";
import {
  ListItemContext,
  ListItemContextModel,
} from "@sgss-ttd-widgets/components";
import { parseISO, format } from "date-fns";
import { getTranslatedValue } from "./fetchData";
import { fr } from "date-fns/locale";

interface Props {
  language: string;
}

const ItemTextTemplate: React.FC<Props> = ({ language }) => {
  const itemContext = useContext<ListItemContextModel | null>(ListItemContext);
  const item: any = itemContext?.item;

  const startDate = parseISO(item?.creationDate);
  const formattedStartDate = `${getTranslatedValue("STARTED", language) +
    " " +
    (language == "FR"
      ? format(startDate, "dd MMM yyyy", { locale: fr })
      : format(startDate, "dd MMM yyyy"))}`;

  const launchDate = parseISO(item?.launchDate);
  const formatedLaunchDate = `${getTranslatedValue("EXPECTED", language) +
    " " +
    (language == "FR"
      ? format(launchDate, "dd MMM yyyy", { locale: fr })
      : format(launchDate, "dd MMM yyyy"))}`;

  return (
    <Fragment>
      <div className="text-secondary text-truncate pe-3">
        {formattedStartDate}
        <span
          className={
            item.isExpired ? "text-danger fw-medium" : "text-secondary"
          }
        >
          {" "}
          - {formatedLaunchDate}
        </span>
      </div>
    </Fragment>
  );
};

export default ItemTextTemplate;
