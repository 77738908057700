import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext } from 'react';
import Pager from '../pager/pager';
import TooltipTrigger from '../tooltip-trigger/tooltip-trigger';
export const ListItemContext = createContext(null);
export default function List({ title, subtitle, items, itemTitlePropertyPath, itemTextPropertyPath, itemIconPropertyPath, itemIconClassesPropertyPath, itemIconTooltipPropertyPath, noBorder, itemHighlightPredicate, itemAlertPredicate, titleTemplate, actionsTemplate, toolbarTemplate, itemTitleTemplate, itemTextTemplate, itemStatusTemplate, itemInfoTemplate, itemActionTemplate, itemBodyTemplate, bodyTemplate, pages, currentPage, isLoading, noDataFiller, dataFetchingErrorFiller, hasDataFetchingError, onTitleClick, onClick, onPageChange }) {
    const generateIcon = (item) => {
        const icon = itemIconPropertyPath && item[itemIconPropertyPath] ? (_jsx("div", Object.assign({ className: `rounded-circle d-flex align-items-center justify-content-center ${itemIconClassesPropertyPath &&
                item[itemIconClassesPropertyPath]}`, style: { height: '42px', width: '42px' } }, { children: _jsx("i", Object.assign({ "aria-hidden": 'true', className: 'icon icon-sm text-primary-alt' }, { children: item[itemIconPropertyPath] })) }))) : (itemStatusTemplate);
        return itemIconTooltipPropertyPath &&
            item[itemIconTooltipPropertyPath] ? (_jsx(TooltipTrigger, Object.assign({ placement: 'left', content: item[itemIconTooltipPropertyPath] }, { children: icon }))) : (icon);
    };
    const generateContent = (items) => {
        if (hasDataFetchingError && dataFetchingErrorFiller) {
            return dataFetchingErrorFiller;
        }
        else if ((!items || items.length === 0) && noDataFiller) {
            return noDataFiller;
        }
        return generateItems(items);
    };
    const generateItems = (items) => items === null || items === void 0 ? void 0 : items.map((item, index) => {
        const hasAlert = itemAlertPredicate && itemAlertPredicate(item);
        const mainPadding = hasAlert ? '2' : '3';
        const statusPlaceholderClasses = itemStatusTemplate || itemIconPropertyPath
            ? ` ps-${mainPadding} pe-4`
            : ` pe-${mainPadding}`;
        const mainContainerClasses = index < items.length - 1 && !noBorder
            ? ' border-bottom border-opacity-40'
            : '';
        const titleClasses = itemHighlightPredicate
            ? itemHighlightPredicate(item)
                ? ' fw-semibold'
                : ''
            : '';
        const wrapperStyles = Object.assign({ cursor: 'pointer' }, (hasAlert
            ? { borderLeft: '8px solid var(--bs-socgen)' }
            : {}));
        return (_jsx(ListItemContext.Provider, Object.assign({ value: { item, index } }, { children: _jsx("div", Object.assign({ className: 'card card-filling', style: wrapperStyles, onClick: () => onClick && onClick({ item }) }, { children: _jsxs("div", Object.assign({ className: 'd-flex' }, { children: [_jsx("div", Object.assign({ className: `d-flex align-items-start flex-column py-3${statusPlaceholderClasses}` }, { children: generateIcon(item) })), _jsxs("div", Object.assign({ className: `d-flex flex-column text-truncate flex-grow-1 py-3 pe-3${mainContainerClasses}` }, { children: [_jsx("div", Object.assign({ className: `d-flex flex-column text-truncate flex-grow-1` }, { children: _jsxs("div", Object.assign({ className: 'd-flex align-items-center justify-content-between mb-1' }, { children: [_jsxs("div", Object.assign({ className: 'd-flex flex-column text-truncate' }, { children: [_jsx("div", Object.assign({ className: `fs-16 text-truncate pe-3 mb-1 ${titleClasses}` }, { children: itemTitleTemplate !== null && itemTitleTemplate !== void 0 ? itemTitleTemplate : (itemTitlePropertyPath
                                                            ? item[itemTitlePropertyPath]
                                                            : '') })), _jsx("div", Object.assign({ className: 'text-secondary text-truncate pe-3' }, { children: itemTextTemplate !== null && itemTextTemplate !== void 0 ? itemTextTemplate : (itemTextPropertyPath
                                                            ? item[itemTextPropertyPath]
                                                            : '') }))] })), _jsx("div", Object.assign({ className: 'd-flex align-items-center' }, { children: itemInfoTemplate }))] })) })), itemBodyTemplate && (_jsx("div", Object.assign({ className: 'd-flex align-items-center' }, { children: itemBodyTemplate })))] }))] })) })) }), index));
    });
    const generateIconSkeleton = () => {
        return (_jsxs("div", Object.assign({ className: "d-flex" }, { children: [_jsx("div", Object.assign({ className: 'd-flex align-items-start flex-column py-3 ps-3 pe-4' }, { children: _jsx("div", { className: 'loading-skeleton block rounded-circle' }) })), _jsxs("div", Object.assign({ className: `d-flex flex-column text-truncate flex-grow-1 py-3 pe-3 border-bottom border-opacity-40` }, { children: [_jsx("div", { className: 'loading-skeleton wp-400' }), _jsx("div", { className: 'loading-skeleton wp-300 mt-2' })] }))] })));
    };
    const generateLineSkeleton = () => {
        return (_jsx("div", Object.assign({ className: 'd-flex flex-column w-100' }, { children: _jsx("div", Object.assign({ className: 'card', style: { cursor: 'pointer' } }, { children: _jsx("div", Object.assign({ className: 'd-flex' }, { children: _jsxs("div", Object.assign({ className: 'd-flex flex-column w-100 text-truncate py-3 px-3' }, { children: [_jsx("div", Object.assign({ className: 'd-flex align-items-center' }, { children: _jsxs("div", Object.assign({ className: 'd-flex flex-column text-truncate flex-grow-1 me-12px' }, { children: [_jsxs("div", Object.assign({ className: 'd-flex align-items-center justify-content-between mb-4px' }, { children: [_jsx("div", Object.assign({ className: 'd-flex flex-column text-truncate' }, { children: _jsx("div", { className: 'loading-skeleton-line mb-2', style: {
                                                            width: '220px',
                                                            height: '12px'
                                                        } }) })), _jsx("div", Object.assign({ className: 'd-flex align-items-center' }, { children: _jsx("div", Object.assign({ className: 'd-flex flex-column align-items-end me-12px' }, { children: _jsx("div", { className: 'loading-skeleton-line mb-2', style: {
                                                                width: '200px',
                                                                height: '12px'
                                                            } }) })) }))] })), _jsxs("div", Object.assign({ className: 'd-flex align-items-center justify-content-between mb-16px' }, { children: [_jsx("div", Object.assign({ className: 'd-flex flex-column text-truncate' }, { children: _jsx("div", { className: 'loading-skeleton-line mb-2', style: {
                                                            width: '300px',
                                                            height: '12px'
                                                        } }) })), _jsx("div", Object.assign({ className: 'd-flex align-items-center' }, { children: _jsx("div", Object.assign({ className: 'd-flex flex-column align-items-end me-12px' }, { children: _jsx("div", { className: 'loading-skeleton-line mb-2', style: {
                                                                width: '60px',
                                                                height: '12px'
                                                            } }) })) }))] }))] })) })), _jsx("div", Object.assign({ className: 'progress progress-sm' }, { children: _jsx("div", { className: 'progress-bar', role: 'progressbar', style: { width: '0%' }, "aria-valuenow": 45, "aria-valuemin": 0, "aria-valuemax": 100 }) }))] })) })) })) })));
    };
    const generateSkeletons = (row, icon) => [...Array(row).keys()].map((line) => (_jsx("div", Object.assign({ className: 'd-flex', style: icon ? { height: '83px' } : { height: '97px' } }, { children: icon ? generateIconSkeleton() : generateLineSkeleton() }), `key_${line}`)));
    return (_jsx("div", Object.assign({ className: 'd-flex flex-wrap flex-grow-1 bg-lvl1 w-100' }, { children: _jsxs("div", Object.assign({ className: 'd-flex flex-column p-4 flex-grow-1 mb-lg-0 mb-4 w-100 h-100 justify-content-between' }, { children: [_jsxs("div", { children: [_jsxs("div", Object.assign({ className: `mx-4px ${toolbarTemplate ? 'mb-12px' : ''}` }, { children: [_jsxs("div", Object.assign({ className: 'd-flex align-items-center justify-content-between', style: { height: '48px' } }, { children: [_jsxs("div", Object.assign({ className: `d-flex text-truncate flex-column ${onTitleClick
                                                ? ' w-100 h-100 px-4px justify-content-center card card-filling me-4px'
                                                : ''}`, style: onTitleClick ? { cursor: 'pointer' } : {}, onClick: () => onTitleClick && onTitleClick() }, { children: [_jsx("h4", Object.assign({ className: 'mb-0 text-truncate' }, { children: titleTemplate ? titleTemplate : title })), subtitle && (_jsx("div", Object.assign({ className: 'text-secondary font-weight-medium text-truncate' }, { children: subtitle })))] })), _jsx("div", Object.assign({ className: 'd-flex flex-column' }, { children: _jsx("div", Object.assign({ className: 'd-flex' }, { children: actionsTemplate })) }))] })), _jsx("div", Object.assign({ className: 'd-flex gap-8px mt-2' }, { children: toolbarTemplate }))] })), isLoading
                            ? generateSkeletons(5, (itemStatusTemplate || itemIconPropertyPath ? true : false))
                            : (bodyTemplate ? _jsx("div", { children: bodyTemplate }) : generateContent(items))] }), _jsx("div", Object.assign({ className: 'd-flex w-100 justify-content-center align-items-end mt-8px' }, { children: _jsx(Pager, { pages: pages, onPageChange: (page) => onPageChange && onPageChange(page), currentPage: currentPage }) }))] })) })));
}
