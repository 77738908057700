import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
    en: {
        translation: {
            viewSelector: {
                unauthorizedAccess: {
                    title: 'Unauthorized access',
                    message: 'You cannot access this widget with the "Navigate as" option.'
                },
                technicalIssue: {
                    title: 'Technical issue',
                    message: 'A problem occurred while loading this content.'
                },
                comingSoon: {
                    title: 'Coming soon',
                    message: 'This widget is under construction.'
                },
                noData: {
                    title: 'No data',
                    message: 'Please make sure the required data exists to load this widget.'
                }
            }
        }
    },
    fr: {
        translation: {
            viewSelector: {
                unauthorizedAccess: {
                    title: 'Accès non autorisé',
                    message: 'Vous ne pouvez pas accéder à ce widget avec l\'option "Navigate as".'
                },
                technicalIssue: {
                    title: 'Problème technique',
                    message: 'Un problème est survenu pendant le chargement de ce contenu.'
                },
                comingSoon: {
                    title: 'Coming soon',
                    message: 'Ce widget est en cours de développement.'
                },
                noData: {
                    title: 'Pas de données',
                    message: 'Veuillez vous assurer que les données nécessaires au chargement de ce widget existent.'
                }
            }
        },
    },
};
i18next
    .use(initReactI18next)
    .init({
    resources,
    lng: "en",
    interpolation: {
        escapeValue: false,
    },
});
export default i18next;
