const modifier = {
    name: 'styleModifier',
    enabled: true,
    phase: 'beforeWrite',
    fn(args) {
        const { state, options } = args;
        const { styles } = state;
        const { popper } = styles;
        const newPopperStyles = Object.assign(Object.assign({}, popper), options.additionalStyles);
        styles.popper = newPopperStyles;
    }
};
const popperStyleModifier = (options) => (Object.assign(Object.assign({}, modifier), { options }));
export default popperStyleModifier;
