export class ClientPortalInput {
  client: Client;
  constructor() {
    this.client = new Client();
  }
}

export class Client {
  bdrCommercialId: string;
  bdrLegalId: string;
  name: string;
  rctId: string;
  constructor() {
    (this.bdrCommercialId = ""),
      (this.bdrLegalId = ""),
      (this.name = ""),
      (this.rctId = "");
  }
}
