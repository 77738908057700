import React, { Fragment } from "react";

interface Props {
  helpClick?: () => void;
  helpText?: string;
}

const ActionTemplate: React.FC<Props> = ({ helpClick, helpText }) => (
  <Fragment>
    <div className="d-flex">
      <button
        className="btn btn-link btn-xl btn-icon p-0"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label="Help"
        title={helpText}
        onClick={() => helpClick && helpClick()}
      >
        <i className="icon material-symbols-outlined">help_outline</i>
      </button>
    </div>
  </Fragment>
);

export default ActionTemplate;
