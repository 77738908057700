var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useOutsideClickListener } from '../hooks/use-outside-click-listener';
const Dropdown = ({ placement, children }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const overlayContent = useRef(null);
    useOutsideClickListener(overlayContent, () => setShow(false));
    return (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ className: "btn btn-link btn-lg py-0 px-0", ref: target, onClick: event => {
                    setShow(!show);
                    event.stopPropagation();
                } }, { children: _jsx("i", Object.assign({ className: "icon" }, { children: "more_vert" })) })), _jsx(Overlay, Object.assign({ target: target.current, show: show, placement: placement !== null && placement !== void 0 ? placement : 'auto', ref: overlayContent }, { children: (_a) => {
                    var { placement: _placement, arrowProps: _arrowProps, show: _show, popper: _popper, hasDoneInitialMeasure: _hasDoneInitialMeasure } = _a, props = __rest(_a, ["placement", "arrowProps", "show", "popper", "hasDoneInitialMeasure"]);
                    return (_jsx("ul", Object.assign({}, props, { className: "dropdown-menu", style: Object.assign({}, props.style), onClick: event => {
                            setShow(false);
                            event.stopPropagation();
                        } }, { children: children })));
                } }))] }));
};
export default Dropdown;
