export const withMock = false; // true

export function getFundFollowUp(
  soAfpBaseUrl: string,
  proceesId: string
): string {
  return soAfpBaseUrl + `funds/${proceesId}/processes`;
}

export function getUserFunds(soAfpBaseUrl: string, pageNumber: number): string {
  return soAfpBaseUrl + `fundsByMails?pageSize=5&pageNumber=${pageNumber}`;
}
