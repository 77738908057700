import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import NoDataFiller from "../no-data-filler/no-data-filler";
import i18n from "../i18n/i18n";
import { useTranslation } from "react-i18next";
const ViewSelector = ({ itemsCount, hasUnauthorizedAccess, hasDataFetchingError, actionsTemplate, unauthorizedAccessFiller, noDataFiller, isComingSoon, children, language, isLoading }) => {
    const { t: translate } = useTranslation();
    if (i18n.language !== language) {
        i18n.changeLanguage(language);
    }
    if (!isLoading) {
        if (hasUnauthorizedAccess) {
            return unauthorizedAccessFiller !== null && unauthorizedAccessFiller !== void 0 ? unauthorizedAccessFiller : (_jsx(NoDataFiller, Object.assign({ title: translate('viewSelector.unauthorizedAccess.title'), icon: "block" }, { children: _jsx("span", { children: translate('viewSelector.unauthorizedAccess.message') }) })));
        }
        else if (hasDataFetchingError) {
            return (_jsx(NoDataFiller, Object.assign({ title: translate('viewSelector.technicalIssue.title'), icon: "error_outline", actionsTemplate: actionsTemplate }, { children: translate('viewSelector.technicalIssue.message') })));
        }
        else if (isComingSoon) {
            return (_jsx(NoDataFiller, Object.assign({ title: translate('viewSelector.comingSoon.title'), icon: "history_toggle_off", actionsTemplate: actionsTemplate }, { children: translate('viewSelector.comingSoon.message') })));
        }
        else if (!itemsCount) {
            return noDataFiller !== null && noDataFiller !== void 0 ? noDataFiller : (_jsx(NoDataFiller, Object.assign({ title: translate('viewSelector.noData.title'), icon: "widgets", actionsTemplate: actionsTemplate }, { children: translate('viewSelector.noData.message') })));
        }
    }
    return _jsx(React.Fragment, { children: children });
};
export default ViewSelector;
