export class ServiceLocator {

    public static register<T>(name: string, type: T) {
        const existing = ServiceLocator.getSingleton().types[name];
        if (!existing) {
            ServiceLocator.getSingleton().types[name] = type;
        }
    }

    public static unregister(name: string) {
        delete ServiceLocator.getSingleton().types[name];
        delete ServiceLocator.getSingleton().instances[name];
    }

    public static get<T>(name: string): T {
        return ServiceLocator.getSingleton().getFromSingleton<T>(name);
    }

    private static singleton: ServiceLocator;

    private static getSingleton(): ServiceLocator {
        if (!this.singleton) {
            this.singleton = new ServiceLocator();
        }
        return this.singleton;
    }

    private types: any;
    private instances: any;

    constructor() {
        this.types = {};
        this.instances = {};
    }

    private getFromSingleton<T>(name: string) {
        const serviceType = this.types[name];
        if (!serviceType) {
            throw new Error("No service registered with name: " + name);
        }

        const service = this.instances[name];
        if (!service) {
            this.instances[name] = new serviceType;
        }
        return this.instances[name] as T;
    }

}
