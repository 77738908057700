import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext } from 'react';
import { StepStatus } from '../models';
export const StepperItemContext = createContext(null);
const Stepper = ({ currentStepIndex, items, stepLabelTemplate, stepIconTemplate }) => (_jsx("div", Object.assign({ className: "row pt-4 px-4 w-100" }, { children: _jsx("div", Object.assign({ className: "col-12" }, { children: _jsx("ul", Object.assign({ className: "stepper text-primary" }, { children: items === null || items === void 0 ? void 0 : items.map((item, index) => {
                const hasCurrentStepIndex = currentStepIndex !== null && currentStepIndex !== undefined;
                currentStepIndex = currentStepIndex;
                const stepClasses = item.style
                    ? [item.style]
                    : [
                        !hasCurrentStepIndex || index === currentStepIndex ? 'current' : null,
                        hasCurrentStepIndex && index > currentStepIndex ? 'inactive' : null,
                        hasCurrentStepIndex && index < currentStepIndex ? 'fill' : null
                    ];
                const alertClass = item.status === StepStatus.Alert ? 'text-socgen' : null;
                const stepIcon = (hasCurrentStepIndex && index >= currentStepIndex)
                    ? _jsx("i", Object.assign({ className: "icon" }, { children: "fiber_manual_record" }))
                    : _jsx("i", Object.assign({ className: "icon" }, { children: "check" }));
                return (_jsx(StepperItemContext.Provider, Object.assign({ value: { item, index } }, { children: _jsxs("li", Object.assign({ className: `stepper-step ${stepClasses.filter(x => x).join(' ')}` }, { children: [_jsx("div", Object.assign({ className: "stepper-step-inner" }, { children: _jsx("div", Object.assign({ className: `stepper-indicator ${alertClass}` }, { children: stepIconTemplate ? stepIconTemplate : stepIcon })) })), _jsx("span", Object.assign({ className: `stepper-label ${alertClass}` }, { children: stepLabelTemplate ? stepLabelTemplate : item.title }))] })) }), index));
            }) })) })) })));
export default Stepper;
