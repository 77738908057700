import { WidgetConfiguration } from "@sg-widgets/shared-core";
import { getSoAfpBaseUrl, getSoUrl } from "../api-url-provider";

export class SoService {
  public static readonly soBaseUrlServiceName: string = "SoBaseUrlService";
  public static readonly soUrlServiceName: string = "SoUrlService";
  public fetchSoAfpBaseUrl(widgetConfiguration: WidgetConfiguration): any {
    const sobaseurl = getSoAfpBaseUrl(widgetConfiguration.environment);
    return sobaseurl;
  }
  public fetchSoUrl(widgetConfiguration: WidgetConfiguration): any {
    const sourl = getSoUrl(widgetConfiguration.environment);
    return sourl;
  }
}
